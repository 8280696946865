<template>
  <v-menu
    max-height="200px"
    nudge-top="0px"
    offset-y
    rounded="lg"
    transition="scroll-y-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-hover>
        <div
          class="tp-btn-overflow rounded"
          :class="btnClass"
          v-bind="attrs"
          v-on="on"
        >
          {{
            items[selected] && items[selected].text && activeItem
              ? items[selected].text
              : placeholder
          }}
          <v-icon :class="{ 'ml-0': dense }" right>mdi-menu-down</v-icon>
        </div>
      </v-hover>
    </template>

    <v-list :dense="dense">
      <template v-if="items.length === 0">
        <v-list-item>Không có dữ liệu</v-list-item>
      </template>
      <template v-else>
        <v-list-item-group
          v-if="activeItem"
          v-model="selected"
          active-class="cyan lighten-5"
          color="primary"
        >
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            link
            @click="selectItem(item, index)"
          >
            <v-list-item-title
              :class="{ 'font-weight-light text-body-1': dense }"
            >
              {{ item.text ? item.text : item }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <template v-else>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            link
            @click="selectItem(item, index)"
          >
            <v-list-item-title
              :class="{ 'font-weight-regular text-body-1': dense }"
            >
              {{ item.text ? item.text : item }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      selected: null
    };
  },
  props: {
    activeItem: {
      type: Boolean,
      default: true
    },
    btnClass: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      require: true
    },
    itemText: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: "Select somthing"
    },
    selectedValue: {
      type: [String, Number]
    }
  },
  watch: {
    selectedValue(val) {
      this.items.every((item, index) => {
        if (item.id === val) {
          this.selected = index;
          return false;
        } else return true;
      });
    }
  },
  created() {
    if (this.selectedValue) {
      this.items.every((item, index) => {
        if (item.id === this.selectedValue) {
          this.selected = index;
          return false;
        } else return true;
      });
    }
  },
  methods: {
    selectItem(item, index) {
      this.selected = index;
      this.$emit("change", index);
      this.$emit("select", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.tp-btn-overflow {
  align-items: center;
  display: flex;
  padding: 8px 16px;
}
</style>
